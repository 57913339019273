import styles from "./BDPortalLandingPage.module.css"

import Navbar from "../common/Navbar";
import Hero from "../common/Hero";
import Section from "../common/Section";
import CardRow from "../common/CardRow";
import DarkCard from "../common/DarkCard";
import CardIcon from "../common/CardIcon";
import Contact from "../common/Contact";
import Footer from "../common/Footer";
import FooterMobile from "../common/FooterMobile";
import DownloadModal from "../common/DownloadModal";

import DataSources from "./_components/DataSources";
import UtilityCompanies from "./_components/UtilityCompanies";

export default function BDPortalLandingPage() {
    return (
        <div className={styles.bdportal}>
            <Navbar />
            <Hero>
                <div className={styles.thumbTopContainer}>
                    <div className={styles.thumbprintTop} />
                </div>
                <div className={styles.logoWrap}>
                    <img src="/images/bd-portal-landing-page/img/bd-portal-blue.svg" alt="BD Portal" />
                </div>
                <h1>Maximise revenue, minimise debt and manage risk</h1>
                <DownloadModal url="/files/IDenteq_BD Portal Overview.pdf" buttonText="Learn how" text="Enter your name & email to download more details and qualify for a free trial or POC" page="bd-portal" hoverColor="white" />
            </Hero>
            <Section className={styles.cardSection}>
                <div className={styles.thumbBottomContainer}>
                    <div className={styles.thumbprintBottom} />
                </div>
                <h2>
                    Commercial Utility supplier debt lists are growing. With 
                    Business Data Portal, you can navigate commercial data 
                    challenges with precision & efficiency
                </h2>
                <CardRow>
                    <DarkCard>
                        <CardIcon src="/images/bd-portal-landing-page/icons/bd-portal-icons/identeq-folder.svg" alt="15 quality data sources" />
                        <p>
                            Use 15 quality data sources to cleanse and append 
                            your entire client base in rolling real-time.
                        </p>
                    </DarkCard>
                    <DarkCard>
                        <CardIcon src="/images/bd-portal-landing-page/icons/bd-portal-icons/identeq-shield.svg" alt="15 quality data sources" />
                        <p>
                            Protect revenue by accurately identifying current 
                            occupiers.
                        </p>
                    </DarkCard>
                    <DarkCard>
                        <CardIcon src="/images/bd-portal-landing-page/icons/bd-portal-icons/identeq-graph.svg" alt="15 quality data sources" />
                        <p>
                            Vastly improve debt collection performance with 
                            proactive COT management.
                        </p>
                    </DarkCard>
                </CardRow>
            </Section>
            <hr />
            <Section className={styles.downloadSection}>
                <div className={styles.splitSection}>
                    <div className={styles.left}>
                        <h2>
                            The innovative and bespoke approach of Business Data 
                            Portal is a game changer for the Business Utilities 
                            market.
                            <br />
                            <br />
                            Take a look today</h2>
                        <DownloadModal url="/files/IDenteq_BD Portal Overview.pdf" text="Enter your name & email to download more details and qualify for a free trial or POC" buttonText="Download" page="bd-portal" hoverColor="black" />
                    </div>
                    <div className={styles.right}>
                        <img src="/images/bd-portal-landing-page/img/bd-portal-ui.svg" alt="" />
                    </div>
                </div>
            </Section>
            <hr />
            <Section className={styles.logosSection}>
                <h2>15 Quality data sources at your fingertips</h2>
                <DataSources />
            </Section>
            <hr />
            <Section className={styles.logosSection}>
                <h2>Some of the people we work with</h2>
                <UtilityCompanies />
            </Section>
            <Contact page="bd-portal" />
            <Footer className={styles.desktopOnly} />
            <FooterMobile className={styles.mobileOnly} />
        </div>
    )
}