import styles from "./css/UtilityCompanies.module.css"

import Image from "../../common/Image"

export default function UtilityCompanies() {
    return (
        <div className={styles.utilityCompanies}>
            <Image className={styles.img} src="/images/bd-portal-landing-page/img/utility-companies/Severn_Trent_Logo.svg" alt="Severn Trent" />
            <Image className={styles.img} src="/images/bd-portal-landing-page/img/utility-companies/United_Utilities_Logo.svg" alt="United Utilities" />
            <Image className={styles.img} src="/images/bd-portal-landing-page/img/utility-companies/AnglianWater_Logo.svg" alt="Anglian Water" />
            <Image className={styles.img} src="/images/bd-portal-landing-page/img/utility-companies/BES_Logo.svg" alt="BES" />
            <Image className={styles.img} src="/images/bd-portal-landing-page/img/utility-companies/Thames_Water_logo.svg" alt="Thames Water" />
            <Image className={styles.img} src="/images/bd-portal-landing-page/img/utility-companies/Business-Stream.svg" alt="Business Stream" />
            <Image className={styles.img} src="/images/bd-portal-landing-page/img/utility-companies/Everflow_Water.svg" alt="Everflow Water" />
            <Image className={styles.img} src="/images/bd-portal-landing-page/img/utility-companies/Wave-Logo.svg" alt="Wave" />
            <Image className={styles.img} src="/images/bd-portal-landing-page/img/utility-companies/Portsmouth-Water.svg" alt="Portsmouth Water" />
            <Image className={styles.img} src="/images/bd-portal-landing-page/img/utility-companies/SSW-Logo.svg" alt="South Staffs Water" />
            <Image className={styles.img} src="/images/bd-portal-landing-page/img/utility-companies/Castle-Water.svg" alt="Castle Water" />
            <Image className={styles.img} src="/images/bd-portal-landing-page/img/utility-companies/Scottish-Water.svg" alt="Scottish Water" />
            <Image className={styles.img} src="/images/bd-portal-landing-page/img/utility-companies/UK-Search.svg" alt="UK Search" />
            <Image className={styles.img} src="/images/bd-portal-landing-page/img/utility-companies/Water-Plus.svg" alt="Water Plus" />
            <Image className={styles.img} src="/images/bd-portal-landing-page/img/utility-companies/Dwr_Cymru_Welsh_Water_Logo.svg" alt="Dwr Cymru Welsh Water" />
        </div>
    )
}