import styles from "./ProspectPortalLandingPage.module.css"

import Navbar from "../common/Navbar";
import Hero from "../common/Hero";
import Section from "../common/Section";
import CardRow from "../common/CardRow";
import DarkCard from "../common/DarkCard";
import CardIcon from "../common/CardIcon";
import Contact from "../common/Contact";
import Footer from "../common/Footer";
import FooterMobile from "../common/FooterMobile";
import DownloadModal from "../common/DownloadModal";

import DataSources from "./_components/DataSources";
import UtilityCompanies from "./_components/UtilityCompanies";

export default function ProspectPortalLandingPage() {
    return (
        <div className={styles.page}>
            <Navbar />
            <Hero>
                <div className={styles.thumbTopContainer}>
                    <div className={styles.thumbprintTop} />
                </div>
                <div className={styles.logoWrap}>
                    <img src="/images/prospect-portal-landing-page/img/prospect-portal-logo.png" alt="Prospect Portal" />
                </div>
                <h1>Sell Faster, Smarter & Boost Productivity</h1>
                <DownloadModal url="/files/Identeq Prospect Portal Overview PDF 24.pdf" buttonText="Learn how" text="Enter your name & email to download more details and qualify for a free trial or POC" page="address-portal" hoverColor="white" />
            </Hero>
            <Section className={styles.cardSection}>
                <div className={styles.thumbBottomContainer}>
                    <div className={styles.thumbprintBottom} />
                </div>
                <h2>
                    Exceptional quality, B2B Prospect Data: 
                    <br/>
                    <br/>
                    Refreshed weekly, data is cleansed, verified & appended, 
                    ready for instant download or integration into your CRM 
                    or sales process.
                </h2>
                <CardRow>
                    <DarkCard>
                        <CardIcon src="/images/prospect-portal-landing-page/icons/prospect-portal-icons/identeq-folder.svg" alt="15 quality data sources" />
                        <p>
                            Find current actively trading businesses and your 
                            perfect prospects, sourced from and cleansed against, 
                            15 key, reliable sources.
                        </p>
                    </DarkCard>
                    <DarkCard>
                        <CardIcon src="/images/prospect-portal-landing-page/icons/prospect-portal-icons/identeq-shield.svg" alt="15 quality data sources" />
                        <p>
                            Stop paying for data you can’t work effectively – 
                            remove poor quality data, saving you time, money and 
                            resources; protecting your ROI.
                        </p>
                    </DarkCard>
                    <DarkCard>
                        <CardIcon src="/images/prospect-portal-landing-page/icons/prospect-portal-icons/identeq-graph.svg" alt="15 quality data sources" />
                        <p>
                            Increase productivity and boost conversion. Use our 
                            advanced filter functionality to refine your data 
                            and find perfect matches against your buyer profile.
                        </p>
                    </DarkCard>
                </CardRow>
            </Section>
            <hr />
            <Section className={styles.downloadSection}>
                <div className={styles.splitSection}>
                    <div className={styles.left}>
                        <h2>
                            Our unique approach to capturing, cleansing & confidence 
                            scoring prospect data is a game-changer for B2B Sales.
                            <br />
                            <br />
                            Take a look today</h2>
                        <DownloadModal url="/files/Identeq Prospect Portal Overview PDF 24.pdf" text="Enter your name & email to download more details and qualify for a free trial or POC" buttonText="Download" page="address-portal" hoverColor="black" />
                    </div>
                    <div className={styles.right}>
                        <img src="/images/prospect-portal-landing-page/img/prospect-ui-alt.svg" alt="" />
                    </div>
                </div>
            </Section>
            <hr />
            <Section className={styles.logosSection}>
                <h2>15 Quality data sources at your fingertips</h2>
                <DataSources />
            </Section>
            <hr />
            <Section className={styles.logosSection}>
                <h2>Some of the people we work with</h2>
                <UtilityCompanies />
            </Section>
            <Contact page="prospect-portal" />
            <Footer className={styles.desktopOnly} />
            <FooterMobile className={styles.mobileOnly} />
        </div>
    )
}